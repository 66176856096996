import React from "react";

const About = () => {
   return (
      <>
         <h1 style={{fontSize: "100px"}}>About</h1>
         <p>This is a simple example of a React component.</p>
      </>
   );
};

export default About;
