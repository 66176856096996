export const Biography = {
   name: "Nguyễn Văn Phú",
   image: "https://res.cloudinary.com/dkhkjaual/image/upload/v1724666517/451806311_1234410304657449_79406_imymlm.png",
   imageBase64:
      "https://res.cloudinary.com/dkhkjaual/image/upload/v1724666517/451806311_1234410304657449_79406_imymlm.png",
   LogoDev: "https://res.cloudinary.com/dkhkjaual/image/upload/v1724684910/logohome_iheuma.svg",
   roles: ["Front-End Developer", "UI/UX Designer", "Programmer"],
   description:
      "I am a Software Engineering (SE) student at HUIT, HCM City. I am continuously learning new things about programming and have worked with various languages and technologies.",
   github: "https://github.com/vanphudev",
   linkedin: "https://www.linkedin.com/in/vanphudev",
   facebook: "https://www.facebook.com/vanphu.huit",
   instagram: "https://www.instagram.com/vanphudev",
   zalo: "https://ln.run/-PmEx",
   cv_download:
      "https://res.cloudinary.com/dkhkjaual/image/upload/v1724685135/CV-NguyenVanPhu-Internship-FrontendDeveloper_4_p1fdtl.pdf",
   cv_view: "https://tinyurl.com/yazam8ur",
   gmail: "mailto:nguyenvanphu.hufi@gmail.com?subject=Hello%20Nguyen%20Van%20Phu",
   listInfo: [
      {
         id: "LinkedIn",
         name: "LinkedIn",
         link: "https://www.linkedin.com/in/vanphudev",
         username: "vanphudev",
         ui: "L",
         text: "LinkedIn",
      },
      {
         id: "Github",
         name: "GitHub",
         link: "https://github.com/vanphudev",
         username: "vanphudev",
         ui: "G",
         text: "GitHub",
      },
      {
         id: "Facebook",
         name: "Facebook",
         link: "https://www.facebook.com/vanphu.huit",
         username: "vanphu.huit",
         ui: "F",
      },
      {
         id: "Instagram",
         name: "Instagram",
         link: "https://www.instagram.com/vanphudev",
         username: "vanphudev",
         ui: "I",
         text: "Instagram",
      },
      {
         id: "Zalo",
         name: "Zalo",
         link: "https://ln.run/-PmEx",
         username: "nguyenvanphu",
         ui: "Z",
      },
      {
         id: "Gmail",
         name: "Gmail",
         link: "mailto:nguyenvanphu.hufi@gmail.com?subject=Hello%20Nguyen%20Van%20Phu",
         username: "nguyenvanphu.hufi@gmail.com",
         ui: "G",
      },
   ],
};

export const skills = [
   {
      title: "Frontend",
      skills: [
         {
            name: "React Js",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/react/react-original.svg",
         },
         {
            name: "Scss",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/sass/sass-original.svg",
         },
         {
            name: "HTML",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/html5/html5-plain-wordmark.svg",
         },
         {
            name: "CSS",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/css3/css3-plain-wordmark.svg",
         },
         {
            name: "JavaScript",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/javascript/javascript-original.svg",
         },
         {
            name: "Bootstrap 5",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/bootstrap/bootstrap-original-wordmark.svg",
         },
         {
            name: "Tailwind css",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/tailwindcss/tailwindcss-original.svg",
         },
         {
            name: "Bootstrap React",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/reactbootstrap/reactbootstrap-original.svg",
         },
      ],
   },
   {
      title: "Backend",
      skills: [
         {
            name: "Node Js",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/nodejs/nodejs-original-wordmark.svg",
         },
         {
            name: "Express Js",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/express/express-original.svg",
         },
         {
            name: "npm",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/npm/npm-original-wordmark.svg",
         },
         {
            name: "MySQL",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mysql/mysql-original-wordmark.svg",
         },
         {
            name: "SQL Server",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/microsoftsqlserver/microsoftsqlserver-original-wordmark.svg",
         },
         {
            name: "Laravel 11",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/laravel/laravel-original.svg",
         },
         {
            name: "Firebase",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/firebase/firebase-original.svg",
         },
      ],
   },
   {
      title: "Programming Languages",
      skills: [
         {
            name: "HTML5",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/html5/html5-plain-wordmark.svg",
         },
         {
            name: "CSS3",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/css3/css3-plain-wordmark.svg",
         },
         {
            name: "SCSS",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/sass/sass-original.svg",
         },
         {
            name: "Java",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/java/java-original.svg",
         },
         {
            name: "JavaScript",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/javascript/javascript-original.svg",
         },
         {
            name: "C#",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/csharp/csharp-original.svg",
         },
         {
            name: "C/C++",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/cplusplus/cplusplus-original.svg",
         },
         {
            name: "PHP",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/php/php-original.svg",
         },
      ],
   },
   {
      title: "Tools Others",
      skills: [
         {
            name: "Git",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/git/git-original.svg",
         },
         {
            name: "GitHub",
            image: "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
         },
         {
            name: "VS Code",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/vscode/vscode-original.svg",
         },
         {
            name: "Postman",
            image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/postman/postman-original.svg",
         },
      ],
   },
];

export const projects = [
   {
      id: 0,
      title: 'Website "Bach Hoa Xanh" Clone',
      date: "March 2024 - May 2024",
      description:
         'This project is a website clone of the "Bach Hoa Xanh" website. It is developed as a part of the "Open Source Programming course".',
      image: "https://res.cloudinary.com/dkhkjaual/image/upload/v1724684786/01_gcfyd3.webp",
      imageBase64: "https://res.cloudinary.com/dkhkjaual/image/upload/v1724684786/01_gcfyd3.webp",
      tags: ["MySQL", "Laravel 11", "PHP", "Ajax", "Jquery", "Bootstrap 5", "Git", "GitHub", "VS Code"],
      category: "web app",
      github: "https://github.com/vanphudev/website_bachhoaxanh_clone",
      webapp: "https://github.com/vanphudev/website_bachhoaxanh_clone",
      member: [
         {
            name: "Nguyen Van Phu",
            img: "https://avatars.githubusercontent.com/u/104127810?v=4",
            linkedin: "https://www.linkedin.com/in/vanphudev/",
            github: "https://github.com/vanphudev",
         },
         {
            name: "Nguyen Van Hoang",
            img: "https://avatars.githubusercontent.com/u/132593416?v=4",
            linkedin: "https://www.linkedin.com",
            github: "https://github.com/Hoang13052003",
         },
      ],
   },
];

export const education = [
   {
      id: 0,
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSg1KtuRhbEuRLAtsHcX5JV_GenWmh5Hg853Q&s",
      school: "Trường Trung Học Cơ Sở Hàm Thắng 2 - Hàm Thuận Bắc - Bình Thuận",
      date: "Aug 2014 - Jun 2018",
      grade: "Junior High School",
      desc: "I completed my class 9 Junior high school education at Trường Trung Học Cơ Sở Hàm Thắng 2 - Hàm Thuận Bắc - Bình Thuận.",
      degree: "Student",
   },
   {
      id: 1,
      img: "https://scontent.fhan3-4.fna.fbcdn.net/v/t39.30808-1/308497667_426864622888740_5095243945393482407_n.jpg?stp=dst-jpg_p200x200&_nc_cat=104&ccb=1-7&_nc_sid=f4b9fd&_nc_ohc=7NyZGg_bXlkQ7kNvgE7KfK3&_nc_ht=scontent.fhan3-4.fna&oh=00_AYA1qt6sCIhuAHM8hx9cVgfLwti86rq9-AB5WtOBMchPCQ&oe=66ADBE3C",
      school: "Trường Trung Học Phổ Thông Phan Chu trinh - Phan Thiết City - Bình Thuận",
      date: "Aug 2018 - Jun 2021",
      grade: "High School",
      desc: "I completed my class 12 High school education at Trường Trung Học Phổ Thông Phan Chu trinh - Phan Thiết City - Bình Thuận.",
      degree: "Student",
   },
   {
      id: 2,
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSose72-aQGXuebKetKNaHXl1uVkxLS0N8lRA&s",
      school: "Trường Đại Học Công Thương Thành Phố Hồ Chí Minh - HUIT",
      date: "Sep 2021 - Apr 2025",
      grade: "University",
      desc: "I am currently studying Software Engineering at HUIT, HCM City.",
      degree: "Student (Software Engineering) - Front-End Developer",
   },
];

export const Certificates = [
   {
      id: 0,
      categories: "Front-End Development",
      programmingLanguages: ["HTML", "CSS"],
      name: "Responsive Web Design.",
      img: "https://res.cloudinary.com/dkhkjaual/image/upload/v1724684869/certification-vanphudev-responsive-web-design_g71plm.png",
      IssuedBy: "freeCodeCamp.org",
      Page: "https://www.freecodecamp.org",
      DateIssued: "July 11, 2024",
      VerificationLink: "https://www.freecodecamp.org/certification/vanphudev/responsive-web-design",
      desc: "This certification is for the completion of the Responsive Web Design course on freeCodeCamp.org.",
   },
];
